
/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-30 14:21
 * @description：销售管理-旅游-旅游管理
 * @update: 2023-05-30 14:21
 */
import {Component, Vue} from "vue-property-decorator";
import {ISystemAdmin} from "@/apis/core/types";
import {
  coachManageBatchAddApi,coachManageStatusUpdateApi,
} from "@/apis/distributionManage/coachManage";
import {Message} from "element-ui";
import {ICoachByPageList} from "@/apis/distributionManage/coachManage/types";
import {tourQueryApi,tourUpAndDownShelfApi} from "@/apis/travelManage";
import router from "@/router";
import config from "@/config";


@Component({})
export default class UserIndex extends Vue {

  queryForm: any = {
    page: 1,
    limit: 10
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  // titleList名称列表
  titleList = ['会员库', '教练库']
  // 会员库
  transferData = []
  // 教练库
  ids = []

  tableData: ISystemAdmin[] = []

  // 新增修改表单
  modelForm: ISystemAdmin = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "主图",
        prop: "tourBannerPic",
        align: 'center',
        imgPrefix: config.downloadUrl,
        type: 'image',
      },
      {
        label: "旅团名称",
        prop: "tourName",
        search: true,
        placeholder: "旅团名称",
        align: 'center',
      },
      {
        label: "地区",
        prop: "tourTypes",
        align: 'center',
        dicData: [{label: '海外', value: 2}, {label: '海内', value: 1},{label: '定制', value: 3}],
      },
      {
        label: "地区",
        prop: "tourTypes",
        align: 'center',
        search: true,
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '海外', value: 2}, {label: '海内', value: 1},{label: '定制', value: 3}],
        hide: true
      },
      {
        label: "地点标签",
        prop: "tourLocationName",
        align: 'center',
        search: true
      },
      {
        label: "旅团售价",
        prop: "tourPrice",
        align: 'center',
      },
      {
        label: "销量",
        prop: "saleQty",
        align: 'center',
      },
      {
        label: "库存",
        prop: "tourStock",
        align: 'center',
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        search: true,
        slot: true,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '下架', value: 2}, {label: '上架', value: 1}],
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    tourQueryApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    tourUpAndDownShelfApi({id: row.id, status: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 打开弹窗
   */
  async openAdd() {
    router.push({path:'/travelManage/add'})
  }

  /**
   * 编辑
   * @param id
   */
  handleEdit(id: string) {
    router.push({path:'/travelManage/add',query:{id}})
  }

  /**
   * 新增教练
   */
  handleAffirm() {
    if (this.ids.length > 0) {
      let ids = this.ids.join(',')
      coachManageBatchAddApi(ids).then(e => {
        if (e) {
          Message.success("新增成功!")
          this.dialogVisible = false
          this.getList()
        }
      })
    } else {
      Message.error("请先选择教练")
    }
  }

  created() {
    this.getList();
  }
}
